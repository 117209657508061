import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Newrali = () => {
  const imageFolder = "newrali";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Graphic design", "Logo", "Development"]}
        imageFolder={imageFolder}
        title="Analysts, investigators, and journalists startup website"
        imageName="newrali"
        subtitle="Newrali"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `A while ago Newrali team set out on a rather ambitious goal to fundamentally transform the way intelligence analysts, investigators, and journalists process, analyze, share, and collaborate together on time sensitive and critical investigations; and in doing so, they launched a beta version of the Newrali platform for organizations to manage their teams and investigations.`,
        ]}
        buttonText="Live website"
        link="https://newrali.handmade.company/newrali"
      />

      <ImageGroup
        columns={2}
        images={["newrali-1", "newrali-2"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <ImageGroup
        columns={1}
        images={["newrali-3"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <InfoSection
        title="New design"
        paragraphs={[
          `The reimagined logo and website reflect our commitment to empowering every intelligence analyst. The intuitive design fosters a harmonious balance between individual workflows and collaborative capabilities. The cohesive visuals, from logo to illustrations, redefine Newrali as a platform that not only understands the complexities of investigations but also celebrates the unique journey of every analyst.`,
        ]}
      />

      <ImageGroup
        columns={1}
        videos={["video.mp4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“As a React.js developer spearheading this transformative project, I embarked on a journey of exploration and ingenuity, driven by the vision of creating a dynamic online presence that would propel Newrali to new heights of success.”`,
        ]}
        name="Ronald H."
        position="Developer"
      />
    </div>
  );
};

export default Newrali;
