import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Virtualclassroom = () => {
  const imageFolder = "classroom";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Web application design"
        imageName="virtualclassroom"
        subtitle="Virtual classroom"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `We created a UI/UX webapp called Virtual Classroom to help students, teachers, and administrators manage the online learning experience. The application provides an easy-to-use interface for teachers to create virtual classrooms, assign tasks, and track student progress. Students can access their classrooms and complete tasks assigned by their teachers, and administrators can monitor classroom activity and student progress.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["classroom-1"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Project start"
        paragraphs={[
          `The design process for Virtual Classroom began with user research to identify the needs of our users. We interviewed teachers and students to understand their experiences with online learning and the challenges they faced. This research was used to inform the design of the application, focusing on creating a user-friendly interface that was intuitive to use.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["classroom-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Target audience"
        paragraphs={[
          `The target audience for Virtual Classroom are students, teachers, and parents. It is designed to be used in both educational and professional settings, making it perfect for students and teachers alike. We are confident that our design will meet the needs of our target audience and provide an effective platform for educators to deliver their lessons in an interactive and engaging manner.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["classroom-3", "classroom-4", "classroom-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Modular grid"
        paragraphs={[
          `The modular grid used for UX/UI design of our app is highly complex and efficient. It is designed to create a consistent, organized structure for the app’s interface, while also allowing for versatility and creativity. The modular grid is based on a  system, with each column representing a “module” that can be used to create unique layouts. This system allows for a greater level of control and flexibility when designing the app’s interface. The column widths are also adjustable, allowing for different widths to be used in different parts of the design. `,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["classroom-8", "classroom-6", "classroom-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Program functions"
        paragraphs={[
          `The webapp includes a variety of features to make the online learning experience more effective and efficient. The main feature is the online collaboration space, which allows students and teachers to work together on projects and assignments in real-time. This includes a shared whiteboard, file sharing, and chat features. The whiteboard is designed to allow teachers and students to easily draw, write, and store information. The file sharing feature allows users to easily share documents and other materials with each other. The chat feature allows users to communicate in real-time and keep the conversation organized.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["classroom-9", "classroom-10"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“One of the most exhilarating challenges was translating the dynamics of face-to-face interaction and classroom engagement into a digital format that felt natural and intuitive. We employed innovative features such as virtual whiteboards, breakout rooms, and real-time feedback mechanisms to replicate the collaborative spirit of traditional classrooms while leveraging the unique advantages of online learning.”`,
        ]}
        name="Elizabeth W."
        position="Designer"
      />
    </div>
  );
};

export default Virtualclassroom;
