import React, { useRef } from "react";

import "./imageSection.css";

const ImageSection = ({ image }) => {
  return (
    <section className="image-section animate-element fade-up onload">
      <img src={image} alt="" />
    </section>
  );
};

export default ImageSection;
