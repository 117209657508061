import React from "react";
import ImageSection from "../../components/shareable/ImageSection";
import Hero from "./../../components/hero/Hero";

import Image from "../../assets/images/services.png";
import DescriptionSection from "../../components/shareable/DescriptionSection";

import "./services.css";
import WhiteTrophySection from "./WhiteTrophySection";
import RenderServices from "./RenderServices";

const icons = ["react", "javascript", "html", "css", "angular", "plus"];

const texts = [
  [
    `Our agency offers comprehensive design, development, and branding services tailored to help SMBs and startups succeed in a competitive market. Our design services focus on creating visually striking, user-centered interfaces that captivate and engage, while our development team brings these designs to life with seamless, high-performance websites and applications. Our branding services craft unique identities that reflect each client’s values and resonate with their target audience.`,
  ],
  [
    `We believe in close collaboration with our clients at every stage. From the initial concept to final delivery, we work hand-in-hand, actively seeking and incorporating feedback to ensure the project aligns with your vision. Our process is iterative, allowing us to refine and adapt based on your input until every detail meets expectations. We are committed to seeing the project through to full success, creating a product that not only meets your satisfaction but also stands out in the market and drives real impact.`,
  ],
];

const Services = ({ projects }) => {
  const description = (
    <>
      <span></span>
      <span>
      Extensive digital services
      </span>
    </>
  );

  return (
    <div className="services-page">
      <Hero title="We’ve got skills to make a statement." />
      <ImageSection image={Image} />
      <DescriptionSection
        icons={icons}
        texts={texts}
        title="Your go-to team for all things digital"
        description={description}
      />
      <WhiteTrophySection />
      <RenderServices projects={projects} />
    </div>
  );
};

export default Services;
