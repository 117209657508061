import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Ellion = () => {
  const imageFolder = "ellion";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Startup ownership management plarform"
        imageName="ellion"
        subtitle="Ellion"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `We wanted to create an intuitive and visually engaging platform, Ellion, aimed at transforming the way startups secure funding and manage ownership, fostering a seamless and transparent ecosystem. Understanding the diverse user base of startup founders, investors, and stakeholders, our design prioritizes a user-centric approach. We crafted a clean and intuitive interface, ensuring a smooth experience for all user profiles.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["ellion-1", "ellion-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Registration process"
        paragraphs={[
          `Registration process is meticulously designed to be swift, user-friendly, and seamlessly integrate cryptocurrency payments for added convenience. Ellion doesn't just streamline registration; it propels startups into a new era of financial flexibility. Embrace efficiency, transparency, and the future of finance`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["ellion-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Functionality"
        paragraphs={[
          `Platform introduces groundbreaking features for ownership transparency, fundraising with votes, and employee payments in cryptocurrency. Witness real-time updates on ownership percentages, fostering collaboration and informed decision-making. Platform supports cryptocurrency payments, ensuring seamless and secure transactions for employee payments. Ellion isn't just about functionality; it's about empowering startups with innovation at every step.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["ellion-4", "ellion-5", "ellion-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Redefining startups"
        paragraphs={[
          `Now, Ellion stands as a cutting-edge platform, where startups can effortlessly manage ownership, raise funds collaboratively, and make secure cryptocurrency payments to employees. The app's innovation lies in its seamless fusion of efficiency, transparency, and forward-thinking financial solutions, marking Ellion as the go-to platform for startups ready to embrace a new era of empowered growth.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["ellion-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“I was drawn to the challenge of creating a digital solution that would streamline the complexities of startup equity management while fostering transparency and ease of use. It was a privilege to contribute to the success of startups by providing them with a powerful tool to manage their equity effectively and drive growth in the ever-evolving landscape of entrepreneurship.”`,
        ]}
        name="Laetitia M."
        position="Designer"
      />
    </div>
  );
};

export default Ellion;
