import React from "react";

const Hover = ({ children }) => {
  return (
    <div className="hover">
      <p>{children}</p>
      <div className="fake">{children}</div>
    </div>
  );
};

export default Hover;
