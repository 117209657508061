import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Barnsley = () => {
  const imageFolder = "barnsley";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Development", "Graphic design"]}
        imageFolder={imageFolder}
        title="Website for luxury hotel"
        imageName="barnsleyhouse"
        subtitle="Barnsley House"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Barnsley House is a luxury hotel located in the idyllic Cotswolds, England. It offers luxurious accommodation, fine dining, and a range of spa and leisure facilities. With its beautiful gardens and stunning views, it is the perfect destination for a relaxing getaway. When designing a website for the hotel, our goal was to create an online experience that encapsulates the luxury and beauty of the hotel. We created a visually stunning website with a modern and minimalistic design, using muted colours and elegant typography to create an atmosphere of luxury and relaxation. We also integrated a booking system to make it easy for visitors to make reservations directly from the website. Additionally, we included an array of high-quality images and videos to showcase the hotel's stunning features. Finally, we integrated comprehensive information about the hotel and its facilities, making it easy for visitors to learn more about the hotel before booking.
          `,
        ]}
        buttonText="Live website"
        link="https://barnsleyhouse.handmade.company/index.html"
      />

      <ImageGroup
        columns={1}
        images={["barnsley-1"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <ImageGroup
        columns={1}
        videos={["video.mp4"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <InfoSection
        title="Design process"
        paragraphs={[
          `Our design process begins with an in-depth conversation with our clients to gain a better understanding of their brand identity and how they want to present themselves online. We then use this information to create a website that accurately reflects their brand. We then use a range of tools and techniques to create a visually stunning website that is easy to navigate and provides our guests with all the information they need. Our team ensures that the website is optimized for both mobile and desktop devices, ensuring an enjoyable user experience regardless of the device being used. Throughout the design process, we continually review our progress with the client to ensure that the website meets their expectations and accurately reflects the unique atmosphere of Barnsley House.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["barnsley-2"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Landing page"
        paragraphs={[
          `Barnsley House is an iconic luxury hotel located in the heart of the Cotswolds. Its website features an eye-catching landing page design that is sure to draw in potential guests. The page features a stunning image of the hotel surrounded by lush greenery, creating a tranquil atmosphere. The page also highlights the various amenities the hotel offers, such as a spa, fine dining, and a well-stocked bar. Overall, the landing page design of the hotel's website is sure to captivate and engage guests, making it the perfect way to introduce them to the luxury experience that awaits them.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["barnsley-3", "barnsley-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Illustration"
        paragraphs={[
          `Our illustrations are inspired by the natural beauty of the local area, and the historic architecture of the hotel itself. From the rolling hills of the Cotswolds to the classic facade of Barnsley House, our illustrations draw visitors into the world of luxury and sophistication that awaits them. They also capture the unique atmosphere of the hotel. From the grandeur of the entrance hallway to the intimate atmosphere of the library, our illustrations create a sense of warmth and relaxation that encourages visitors to linger and explore. `,
        ]}
      />

      <ImageGroup
        columns={3}
        images={["barnsley-5", "barnsley-6", "barnsley-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Detail attention"
        paragraphs={[
          `Our design team takes great care to ensure that the visuals and functionality of our website accurately reflect the quality and sophistication of our hotel. We use high-resolution images to capture the beauty of the grounds and interior of Barnsley House, and our website is easy to navigate so that guests can find the information they need quickly. We also strive to create a consistent look across all pages of the website, so that users get a sense of the luxury and elegance of our property with each page they visit.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["barnsley-8"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Developing the website for Barnsley House, was a thrilling and dynamic experience that pushed the boundaries of web development. From the outset, it was evident that this project would be defined by its immersive animations and rich visual storytelling, setting it apart in the competitive hospitality industry.”`,
        ]}
        name="Ronald H."
        position="Developer"
      />
    </div>
  );
};

export default Barnsley;
