import React from "react";

const ImageGroup = ({
  columns,
  images = [],
  videos = [],
  imageFolder,
  onlyDesktop,
  firstImage,
  noSpace,
}) => {
  return (
    <div
      className={`image-group container ${
        noSpace ? "image-space" : "section-space"
      } animate-element fade-up ${firstImage ? "onload" : ""}`}
      style={{
        gridTemplateColumns: `repeat(${columns},1fr)`,
        marginTop: firstImage ? 0 : "",
      }}
    >
      {images.length > 0 &&
        images?.map((image, index) => (
          <picture key={index}>
            {!onlyDesktop && (
              <>
                <source
                  media="(max-width: 599px)"
                  srcSet={
                    process.env.PUBLIC_URL +
                    `/projectImage/${imageFolder}/${image}-mob.jpg`
                  }
                />
                <source
                  media="(min-width: 600px)"
                  srcSet={
                    process.env.PUBLIC_URL +
                    `/projectImage/${imageFolder}/${image}.jpg`
                  }
                />
              </>
            )}
            <img
              src={
                process.env.PUBLIC_URL +
                `/projectImage/${imageFolder}/${image}.jpg`
              }
              alt=""
            />
          </picture>
        ))}

      {videos.length > 0 &&
        videos?.map((video, index) => (
          <video autoPlay muted playsInline loop>
            <source
              src={
                process.env.PUBLIC_URL + `/projectVideo/${imageFolder}/${video}`
              }
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        ))}
    </div>
  );
};

export default ImageGroup;
