import React, { useRef, useState } from "react";

import Video from "../../assets/video/reel.mp4";
import MovableButton from "../../components/shareable/MovableButton";
import ReelModal from "./ReelModal";

const ShowReel = () => {
  const showReelRef = useRef(null);
  const videoRef = useRef(null);

  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <div className="section-wrapper container">
        <section
          id="show-reel"
          className="show-reel  animate-element fade-up onload"
          ref={showReelRef}
          onClick={() => setOpenModal(true)}
        >
          <div className="wrapper">
            <video
              id="showreel-video"
              ref={videoRef}
              autoPlay
              muted
              playsInline
              loop
            >
              <source src={Video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </section>

        <MovableButton type="btn" name="play" containerRefs={[showReelRef]} />
      </div>
      {openModal && (
        <ReelModal close={() => setOpenModal(false)} show={openModal} />
      )}
    </>
  );
};

export default ShowReel;
