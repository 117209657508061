import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Favtap = () => {
  const imageFolder = "favtap";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Logo"]}
        imageFolder={imageFolder}
        title="Trip planning app"
        imageName="favtap"
        subtitle="Favtap"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Embark on a journey of seamless trip planning and shared adventures with Favtap, the ultimate mobile app designed to organize and inspire travel experiences. Favtap is not just a travel planner; it's go-to companion for creating, discovering, and sharing unforgettable trips with friends, family, and fellow explorers. More convenient way to plan and experience next adventures!`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["favtap-1", "favtap-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Main features"
        paragraphs={[
          `With Favtap intuitive home screen, every travel category, draft, and event is effortlessly organized, putting users journey in the palm of his hand. Categories, trip drafts, and upcoming events are just a glance away. Quickly toggle to "Travel Mode," pick your destination, and experience seamless on-the-go planning. User can dive deeper into his interests by exploring single categories, where a curated list of trips awaits. The countdown feature keeps users excited about upcoming adventures, while the clean design ensures everything is accessible at a glance.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["favtap-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Aesthetic precision"
        paragraphs={[
          `The paid plans section reflects a harmonious blend of aesthetics and functionality, with a focus on clarity and simplicity. The advanced search functionality, a designer's touch, ensures that finding specific trips or details is an intuitive and visually pleasing experience.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["favtap-4", "favtap-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Onboarding experience"
        paragraphs={[
          `Once you enter your email, app send a confirmation link for user to verify email address. A simple click on the link seamlessly moves user forward. Enhancing security and personalization, Favtap encourage you to add your phone number. After inputting phone number, a unique verification code is sent via SMS. With this meticulous approach, Favtap prioritizes the safety of users account while offering a swift and user-friendly onboarding experience.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["favtap-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Unique feature"
        paragraphs={[
          `Easily tailor experience by adding or editing categories that reflect users unique travel interests. In the Settings section, take charge of users account's security with options like adding a backup phone and choosing personalized login methods for enhanced protection. Explore our standout feature, "New item" where user can add a touch of personalization to his travels. Whether it's a restaurant name, hashtags, plan dates, or attaching images, this unique feature empowers user to create personalized entries that capture the essence of his journey.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["favtap-7", "favtap-8"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <ImageGroup
        columns={1}
        images={["favtap-9"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <ImageGroup
        columns={1}
        images={["favtap-10"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <InfoSection
        title="Dark mode"
        paragraphs={[
          `Additionally, we understand the importance of catering to diverse user preferences. That's why we're excited to introduce our dark mode design. Carefully crafted for visual comfort, the dark mode offers a sleek and modern aesthetic while reducing eye strain in low-light environments. It's not just about style; it's about enhancing usability and ensuring that Favtap adapts to your preferences and surroundings. While iPad version retains all the intuitive features and functionalities, allowing you to plan and organize your travels effortlessly with the convenience of a tablet.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["favtap-11"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Drawing inspiration from the excitement of travel and the beauty of diverse cultures, we crafted a design that exuded wanderlust and adventure. From vibrant color palettes and sleek typography to immersive imagery and seamless transitions, every element of the app was meticulously curated to evoke a sense of exploration and discovery.”`,
        ]}
        name="Vasilije D."
        position="Designer"
      />
    </div>
  );
};

export default Favtap;
