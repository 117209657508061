import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Viziyaarchitecture = () => {
  const imageFolder = "viziya";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Development"]}
        imageFolder={imageFolder}
        title="Architecture studio website design"
        imageName="viziya"
        subtitle="Viziya"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Viziya Architecture team wanted to create a website that allowed potential clients to explore their work and services in an engaging and modern way. To meet their needs, we designed a website that showcased their portfolio in an impressive and interactive way. The website was designed to be simple and easy to navigate, with clear navigation menus, visuals and text that clearly explain their services and portfolio.`,
        ]}
        buttonText="Live website"
        link="https://www.viziya.handmade.company/index.html"
      />

      <ImageGroup
        columns={1}
        images={["viziya-1"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Unique experience"
        paragraphs={[
          `Viziya Architecture Team wanted to create a unique website to showcase their work and stand out from the competition. We incorporated a number of design features into the website to make it visually appealing and easy to navigate. We used a combination of images, fonts, and colors that were consistent throughout the website in order to create a cohesive aesthetic. We also utilized a simple navigation structure that was easy to follow and allowed users to quickly find what they were looking for. `,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["viziya-4", "viziya-7", "viziya-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Production result"
        paragraphs={[
          `Thanks to our great developers, we were able to reproduce the all details from design. At Handmade you will not find the boring and corporate ethics that we often see on many similar sites. The site has received high marks internationally for its chosen approach and presentation of information, which significantly increases user engagement.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["viziya-2", "viziya-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Awards"
        paragraphs={[
          `We are proud to announce that our website has been awarded with prestigious award from the Awwwards. The Award is the "Honorable mention" which is awarded to the website with the highest quality of design and user experience. We are honored to have received this award as it is a testament to our hard work and dedication to making our website the best it can be.`,
        ]}
      />

     <ImageGroup
        columns={1}
        images={["viziya-11"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Crafting a unique website for an architecture studio with React.js was akin to building a digital masterpiece that captured the essence of creativity, elegance, and innovation. As the developer behind this visionary project, I embarked on a journey of exploration and invention, guided by the vision of creating a virtual showcase that would not only showcase the studio's portfolio but also immerse visitors in a world of architectural wonder.”`,
        ]}
        name="Illia J."
        position="Developer"
      />
    </div>
  );
};

export default Viziyaarchitecture;
