import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Restore = () => {
  const imageFolder = "restore";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Electronic devices seller"
        imageName="restore"
        subtitle="Restore"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Restore is a e-commerce company that specializes in offering a diverse range of premium electronic devices. The company is dedicated to providing customers with top-notch products, cutting-edge technology, and a seamless online shopping experience. we embarked on a comprehensive website design project aimed at enhancing their online presence and creating an immersive digital storefront. Our goal was to showcase the extensive product range, streamline the user experience, and reinforce Restore's commitment to quality and innovation.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["restore-1", "restore-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Seamless journey"
        paragraphs={[
          `We've introduced a transformative design approach aimed at providing users with an enhanced and visually compelling shopping experience. This innovative design incorporates a strategic placement of special offers below the menu, integrates a seamless search input within the menu for improved accessibility, and employs a dynamic card-based layout to showcase banners and products. Every element is meticulously chosen to align with user preferences, ensuring that the design enhances both functionality and aesthetics, creating a unique and memorable journey for online shoppers.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["restore-3", "restore-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Crafting the website for an electronic devices seller was akin to sculpting the digital embodiment of innovation and connectivity. As the designer behind this dynamic project, I embarked on a journey of exploration and creativity, guided by the vision of creating a virtual marketplace that would not only showcase cutting-edge technology but also spark excitement and inspiration in users.”`,
        ]}
        name="Nikolay K."
        position="Designer"
      />
    </div>
  );
};

export default Restore;
