import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Betero = () => {
  const imageFolder = "betero";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Sports betting platform"
        imageName="betero"
        subtitle="Betero"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Betero is a revolutionary new betting platform that is revolutionizing the way people wager on their favorite sporting events. With its cutting-edge technology, Betero is the first platform to allow users to bet with cryptocurrency, making it a much more secure, convenient, and flexible way to bet online. Overall, we designed Betero to be a secure, reliable and intuitive betting platform that is powered by blockchain technology and crypto assets. We believe that this combination of features provides users with a unique and enjoyable betting experience.
          `,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["betero-1", "betero-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Unique experience"
        paragraphs={[
          `When designing the unique experience for Betero’s new betting platform, we wanted to make sure that the entire betting experience was as seamless and secure as possible. We wanted to ensure that the platform was able to easily integrate with existing systems, allowing users to quickly and securely place bets. To achieve this, we built the platform on a blockchain technology that allows for secure and fast transactions, eliminating the need for intermediaries. We also made sure that the platform was user-friendly, providing an intuitive interface and easy access to the markets. `,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["betero-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Empower flows"
        paragraphs={[
          `We started by mapping out all of the user activities that would take place on the platform, from signing up to placing bets. Then, we broke down each activity into the different steps that would need to be taken to complete the task. We took care to make sure that each step was easy to understand and find. Next, we designed the user interface to be as intuitive as possible. We focused on making sure that users could find the information they needed quickly and easily. We also made sure that users could place bets with minimal inputting of data and that the process was as straightforward as possible.
          `,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["betero-4", "betero-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Adaptive design"
        paragraphs={[
          `We incorporated responsive design principles to create an adaptive design that works on different devices and browsers and also made sure that the design was easy to navigate and intuitive for all users. We used simple and easy-to-understand language to help users quickly understand how to use the platform. We also included clear, visible call-to-actions to encourage users to interact with the platform.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["betero-6", "betero-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Betting experience"
        paragraphs={[
          `We started by identifying user needs, goals, and pain points, and then conducted user research to create a user-centered design strategy. The betting experience at Betero is also designed to be as user-friendly as possible. We designed an interface that makes it easy to find and place bets. Users can easily view their betting history and any available bonuses`,
        ]}
      />

      <ImageGroup
        columns={3}
        images={["betero-8", "betero-9", "betero-10"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Finding events"
        paragraphs={[
          `We improved user experience, introducing new navigation elements, components and modules. Platform offers a vast majority of events to bet on for any taste. Sort them out by category with the filter button, with tags and colors. Users can find their bets with just one click.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["betero-11", "betero-12"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Other pages"
        paragraphs={[
          `In this ambitious and fast-paced project, we communicated daily with Betero marketing team, iterating rapidly and sharing new designs as they were created. We did more then 100 screens. We’re looking forward to continuing our work with Betero, helping them fulfil their mission.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["betero-13"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Designing the user experience and UI for Betero, a cutting-edge betting platform, was an exhilarating and immersive journey into the world of sports and gaming. From the outset, it was clear that we were embarking on a project that demanded innovation, creativity, and a deep understanding of user behavior.”`,
        ]}
        name="Vasilije D."
        position="Designer"
      />
    </div>
  );
};

export default Betero;
