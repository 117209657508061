import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Execwebsite = () => {
  const imageFolder = "execwebsite";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Development"]}
        imageFolder={imageFolder}
        title="SaaS landing page"
        imageName="exec"
        subtitle="Exec"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `The design of a landing page for Exec is an essential part of the customer experience. We strive to create an engaging and user-friendly experience for visitors to your product. We created an eye-catching hero image that conveys the brand and product. We also crafted compelling copy that explains the features and benefits of the product in an easy-to-understand way.`,
        ]}
        buttonText="Live website"
        link="https://www.exec.handmade.company/index.html"
      />

      <ImageGroup
        columns={1}
        images={["exec-1"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Process"
        paragraphs={[
          `Once we have a clear understanding of the target audience, we develop a design concept that focuses on the key features and benefits of the product. We also consider other elements such as colors, fonts, images, and layout. We create several versions of the page, testing each to determine which one resonates most with customers and provides the best conversion rate.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["exec-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“As a developer, my role involved translating the design concepts into fully functional web pages using a combination of HTML, CSS, JavaScript, and other web technologies. I focused on ensuring that the website was not only visually appealing but also user-friendly, responsive, and optimized for performance across various devices and browsers.”`,
        ]}
        name="Marjan D."
        position="Developer"
      />
    </div>
  );
};

export default Execwebsite;
