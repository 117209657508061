import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Jetprojectlanding = () => {
  const imageFolder = "jetproject";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Development"]}
        imageFolder={imageFolder}
        title="Private jets company website design"
        imageName="jet"
        subtitle="Jet Project"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Jetproject is a private jet charters company that wanted to create a modern website to help them attract new customers. To help them reach their goals, we developed a website design that would incorporate the company’s branding and showcase their services in an attractive and easy-to-use manner.`,
        ]}
        buttonText="Live website"
        link="https://www.jetproject.handmade.company/index.html"
      />

      <ImageGroup
        columns={2}
        images={["jp-1", "jp-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Design solution"
        paragraphs={[
          `We started by creating a custom color palette and font selection that would best represent the company’s brand. We then developed a responsive website design that was optimized for both desktop and mobile devices. The website was designed with the user experience in mind, making it easy for customers to find the information they need and to book their charter flights quickly and efficiently. We are proud of the website we created for Jetproject, which has allowed them to attract new customers and reach their goals. The website has been well-received by customers and has become an indispensable asset for the company`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["jp-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Collaborating closely with the Jet Project team, we delved into the essence of luxury travel, seeking to capture the sophistication and prestige synonymous with private jet experiences. Our goal was to create an online presence that would not only showcase Jet Project's fleet of aircraft but also evoke the unparalleled service and attention to detail that set them apart in the industry.”`,
        ]}
        name="Vasilije D."
        position="Designer"
      />
    </div>
  );
};

export default Jetprojectlanding;
