import React, { useRef } from "react";
import Svg from "../../components/shareable/Svg";

const Location = ({location, adress,image}) => {
  const containerRef = useRef(null);
  const elementRef = useRef(null);

  return (
    <section
      className="location"
      ref={containerRef}
    >
      <img src={image} alt="" ref={elementRef} />

      <div className="info-details">
        <div className="icon-circle">
          <Svg name="location" />
        </div>
        <div className="info-text">
          <h2>{location}</h2>
          <p>{adress}</p>
        </div>
      </div>
    </section>
  );
};

export default Location;
