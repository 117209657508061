import React from "react";
import Hover from "../../components/shareable/Hover";
import Svg from "../../components/shareable/Svg";

const InfoSection = ({
  title,
  paragraphs,
  buttonText = "",
  name = "",
  position,
  link = "",
  blog = false,
}) => {
  const words = title.split(" ");

  return (
    <section
      className={`project-info container-narrow section-space animate-element fade-up ${
        blog ? "blog-info" : ""
      }`}
    >
      <h2>
        {blog
          ? title
          : words.map((word) => {
              return (
                <>
                  <p>{word}</p>
                </>
              );
            })}
      </h2>

      <div className="wrapper">
        {name !== "" && (
          <div className="subtitle">
            <span className="line">—</span>
            {name}, <span className="opacity">{position}</span>
          </div>
        )}

        <div className="paragraph">
          {paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>

        {buttonText !== "" && (
          <a href={link}>
            <div className="project-button">
              <Svg name="arrow" />
              <p>{buttonText}</p>
            </div>
          </a>
        )}
      </div>
    </section>
  );
};

export default InfoSection;
