import React from "react";
import Hover from "../../components/shareable/Hover";
import Svg from "../../components/shareable/Svg";

import Email from "../../assets/icons/email.svg";

const Info = () => {
  return (
    <section className="info container-narrow animate-element fade-up onload">
      <div className="line">
        <p className="opacity">Drop us a line at</p>
        <div className="icons">
          <Hover>
            <a href="https://t.me/buzzingroup" className="wrapper">
              <Svg name="telegram" />
            </a>
          </Hover>
          <Hover>
            <a href="https://wa.me/381601443221" className="wrapper">
              <Svg name="whatsapp" />
            </a>
          </Hover>
        </div>
      </div>

      <div className="email">
        <a href="mailto:hi@handmade.company">
          <Hover>
            <img src={Email} alt="" />
          </Hover>
        </a>
      </div>

      <div className="line">
        <div className="left">
          <span className="opacity">Or </span>
          just call us
          <span className="opacity"> and share your story with us</span>
        </div>
        <Hover>
          <a href="tel:+381 60 144 3221">
            <div className="phone">+381 60 144 3221</div>
          </a>
        </Hover>
      </div>
    </section>
  );
};

export default Info;
