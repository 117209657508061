import React, { useState, useRef, useEffect } from "react";
import Svg from "../../components/shareable/Svg";

const FaqItem = ({ item }) => {
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);

  const containerRef = useRef(null);

  useEffect(() => {
    const handleHeight = () => {
      setHeight(containerRef.current.scrollHeight);
    };

    handleHeight();

    window.addEventListener("resize", handleHeight);

    return () => window.removeEventListener("resize", handleHeight);
  }, []);

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div className="faq-item animate-element fade-up" data-parent="true">
      <div className="visible" onClick={toggleOpen}>
        <div className="question">{item.question}</div>
        <div
          className="icon-wrapper"
          style={{ transform: open ? "rotate(45deg)" : "rotate(0deg)" }}
        >
          <Svg name="plus" />
        </div>
      </div>

      <div
        className={`answer ${open && "margin-top"}`}
        style={{
          maxHeight: open ? height : 0,
          transform: open ? "translateY(0px)" : "translateY(7.5px)",
          opacity: open ? 0.5 : 0,
        }}
        ref={containerRef}
      >
        {item.answer}
      </div>
    </div>
  );
};

export default FaqItem;
