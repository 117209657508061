import React from "react";

const counts = [
  {
    number: "10+",
    text: "team members",
  },
  {
    number: "200+",
    text: "completed projects",
  },
  {
    number: "10m",
    text: "USD raised by our clients",
  },
];

const Counter = () => {
  return (
    <section className="counter container-narrow section-space animate-element fade-up">
      {counts.map((count, index) => (
        <div className="item" key={index}>
          <div className="wrapper">
            <div className="number">{count.number}</div>
            <div className="text">{count.text}</div>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Counter;
