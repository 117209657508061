import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Dylan = () => {
  const imageFolder = "dylan";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Website design for hotel"
        imageName="dylan"
        subtitle="Dylan Amsterdam"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `We created a website for the Hotel Dylan Amsterdam to provide their customers with a convenient way to find information and book reservations. Our team designed a modern, intuitive website that is easy to navigate and provides all the necessary information.
          `,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["dylan-1"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Main mission"
        paragraphs={[
          `With our website, customers can easily browse and find the information they need and make reservations quickly and securely. We have also integrated several payment methods, so customers can pay for their reservations in their preferred currency. The website features a modern, clean design that is easy to navigate and provides a great user experience. It has a simple, responsive layout that adapts to different devices and screen sizes, making it easy for visitors to access the information they need regardless of their device.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["dylan-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Designing the UI/UX for the website of Dylan Hotel Amsterdam was a captivating and immersive experience that allowed me to dive deep into the world of luxury hospitality and Dutch culture. From the outset, I was inspired by the opportunity to create a digital experience that would capture the elegance, charm, and sophistication of this iconic boutique hotel.”`,
        ]}
        name="Andrej P."
        position="Designer"
      />
    </div>
  );
};

export default Dylan;
