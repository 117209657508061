import React from "react";

import "./descriptionSection.css";
import Svg from "./Svg";

const DescriptionSection = ({ title, icons, texts, description }) => {
  return (
    <section className="description-section container-narrow section-space animate-element fade-up">
      <h2>{title}</h2>

      <div className="text-wrapper">
        {texts[0].map((text, index) => (
          <p key={index} className="description">
            {text}
          </p>
        ))}
      </div>

      <div className="text-wrapper">
        {texts[1].map((text, index) => (
          <p key={index} className="description">
            {text}
          </p>
        ))}
      </div>

      <div className="bottom">
        <div className="circles">
          {icons.map((icon, index) => (
            <div
              className={`circle ${icon === "plus" ? "plus" : ""}`}
              key={index}
            >
              <Svg name={icon} />
            </div>
          ))}
        </div>
        <div className="desc">{description}</div>
      </div>
    </section>
  );
};

export default DescriptionSection;
