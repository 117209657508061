import React from "react";

import Trophy from "../../assets/images/trophy.png";

const WhiteTrophySection = () => {
  return (
    <section className="trophy-section section-space ">
      <div className="container-narrow">
        <div className="left animate-element fade-up">
          <div className="subtitle">Our motto</div>
          <h2>
            When all the parts come together, extraordinary things can happen
          </h2>
        </div>
      </div>
      {/* <div className="image">
        <img src={Trophy} alt="" />
      </div> */}
    </section>
  );
};

export default WhiteTrophySection;
