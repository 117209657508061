import React from "react";
import Hero from "../../components/hero/Hero";
import RenderBlogs from "./RenderBlogs";

import "./projects.css";

const Blogs = ({ blogs }) => {
  return (
    <div className="content-hero">
      <Hero title="Sharing our knowledge on a wide range of topics" />
      <RenderBlogs projects={blogs} />
    </div>
  );
};

export default Blogs;
