import React from "react";
import Svg from "./Svg";

import "./button.css";

import { Link } from "react-router-dom";

const Button = ({ type, text, href = "", animate = false }) => {
  return (
    <>
      {type === "scroll" ? (
        <a
          href={href}
          className={`button-${type} ${
            animate ? "animate-element fade-up" : ""
          }`}
        >
          <Svg name="arrow" />
        </a>
      ) : (
        <Link
          to={href}
          className={`button-${type} ${
            animate ? "animate-element fade-up" : ""
          }`}
        >
          <Svg name="arrow" />
          <p>{text}</p>
        </Link>
      )}
    </>
  );
};

export default Button;
