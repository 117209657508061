import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Hyperbolic = () => {
  const imageFolder = "hyperbolic";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Development", "Graphic design"]}
        imageFolder={imageFolder}
        title="A decentralized data transmission startup website"
        imageName="hyperbolic"
        subtitle="Hyperbolic"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `In a groundbreaking stride, we proudly introduce our revolutionary Multi-tier Decentralized Data Transmission startup, poised to disrupt the internet landscape. This innovation addresses the diverse needs of the digital era, emphasizing unparalleled solutions for Latency, Privacy, and Reliability.`,
        ]}
        buttonText="Live website"
        link="https://hyperbolic-platform.webflow.io/"
      />

      <ImageGroup
        columns={1}
        images={["hyperbolic-1"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Crafting the website for Hyperbolic Platform, a digital ecosystem fusing creativity and technology, was akin to orchestrating a symphony of innovation and imagination. From the outset, I was immersed in a world where boundaries blurred, and possibilities expanded beyond the confines of convention.”`,
        ]}
        name="Vasilije D."
        position="Designer"
      />
    </div>
  );
};

export default Hyperbolic;
