import React from "react";
import ImageSection from "../../components/shareable/ImageSection";
import Hero from "./../../components/hero/Hero";

import AboutImage from "../../assets/images/about.jpg";
import DescriptionSection from "../../components/shareable/DescriptionSection";
import RenderAward from "./RenderAward";

import "./about.css";
import Counter from "./Counter";
import Logos from "./Logos";
import Faq from "./Faq";

const icons = ["award1", "award2"];

const texts = [
  [
    `In 2019, a group of skilled professionals came together with a shared vision: to create something extraordinary by leveraging the power of collaboration. Each of us had honed our craft on different projects, across a variety of industries, bringing unique perspectives and expertise to the table. However, we all shared one fundamental insight—true strength lies in community, not in individuality.`,
  ],
  [
    `Driven by this belief, we founded Handmade company®. We realized that by uniting our skills in design, development, and strategy, we could achieve far more together than we ever could alone. This foundation of teamwork and shared purpose allows us to create impactful digital experiences that help brands stand out and thrive. Today, this spirit of collaboration continues to fuel our work, as we build solutions that not only meet the needs of our clients but inspire growth and transformation.`,
  ],
];

const About = () => {
  const description = (
    <>
      <span>20+</span>{" "}
      <span>
        awards and <br /> nomenees
      </span>
    </>
  );
  return (
    <>
      <Hero title="From Serbian roots to global horizons" />
      <ImageSection image={AboutImage} />
      <DescriptionSection
        icons={icons}
        texts={texts}
        title="Our story"
        description={description}
      />
      <RenderAward />
      <Counter />
      <Logos />
      <Faq />
    </>
  );
};

export default About;
