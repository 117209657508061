import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Wavelle = () => {
  const imageFolder = "wavelle";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Crypto investment app"
        imageName="wavelle"
        subtitle="Wavelle"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Wavelle is a mobile app that provides users with real-time market data and insights on stocks, ETFs, options, and more. We designed the UI/UX of the Wavelle mobile app with a dark mode to reduce eye strain and improve user experience. We used a black background with bright, contrasting colors to make the app easier to read and navigate.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["wavelle-1", "wavelle-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Approach"
        paragraphs={[
          `Wavelle is a mobile application that was designed with a focus on creating a positive user experience. We approached the UI/UX design of this app with the goal of creating an intuitive and engaging experience for users. Our design team started by creating a simple and intuitive user interface, which consists of a sleek and modern aesthetic with easy-to-understand navigation.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["wavelle-3", "wavelle-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Some functionality"
        paragraphs={[
          `Onboarding helps to quickly show the value of the product, learn to work with it, and retain the user. Easily add card in the application, you do not need to switch to other additional services, just add your card and start buying crypto.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["wavelle-5", "wavelle-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Best cooperation"
        paragraphs={[
          `We value every single client and do our best to offer multiple options for working together in a way that makes everyone happy. The most reliable and mutually beneficial way is when the client trusts in your expertise and you can meet multiple needs during the course of a long term working relationship. Wavelle gave us that opportunity to show them what we can do.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["wavelle-7", "wavelle-8"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Drawing inspiration from the dynamic energy of the crypto community and the sleek sophistication of modern design, we crafted a visual identity that balanced innovation with accessibility. From the minimalist user interface to the vibrant color palette and intuitive navigation, every element of the app was meticulously curated to create a seamless and engaging user experience.”`,
        ]}
        name="Niah W."
        position="Designer"
      />
    </div>
  );
};

export default Wavelle;
