import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";
import Hero from "../../components/hero/Hero";

const Blog2 = () => {
  const imageFolder = "blog2";
  return (
    <div className="project-template">
      <Hero
        title="The science of creating award-winning websites"
        blog={"12 FEB 2024"}
        minHeight
      />
      <ImageGroup
        columns={1}
        images={["blog-1"]}
        imageFolder={imageFolder}
        onlyDesktop
        firstImage
      />

      <InfoSection
        title="Creative brainstorming and concept development"
        paragraphs={[
          `Our process begins with collaborative brainstorming sessions that bring together our designers, developers, and strategists to align on the project’s vision. We dive into the brand’s personality, audience, and objectives to spark ideas that inspire. During this phase, we explore various color palettes, layout ideas, and style inspirations through mood boards and wireframes, setting a strong foundation for a design that’s visually captivating and user-friendly.`,
          `Key Elements:`,
          `1. Mood Boards to gather visual inspiration and establish the aesthetic direction.`,
          `2. Wireframes to map out the structure and flow of the site.`,
          `3. Team Feedback Loops to refine ideas and ensure a cohesive vision.`
        ]}
        blog
      />

      <ImageGroup columns={1} images={["blog-2"]} imageFolder={imageFolder} onlyDesktop />

      <InfoSection
        title="Crafting a unique, immersive visual identity"
        paragraphs={[
          `With the initial concept established, we focus on bringing the brand to life through detailed design. Every element is crafted to create an emotional connection, from the typography to custom graphics and color selection. Our designers carefully select fonts and colors that align with the brand’s tone, creating a visual language that’s both beautiful and meaningful.`,
          `Key Focus Areas:`,
          `1. Typography and Color: Choosing fonts and color schemes that reinforce the brand identity and enhance readability.`,
          `2. Custom Icons and Graphics: Creating unique visual elements that add personality and make the brand memorable.`,
          `3. Engaging Micro-Interactions: Subtle animations and hover effects enrich the user experience, adding depth and interest without overwhelming the design.`
        ]}
        blog
      />

      <ImageGroup
        columns={1}
        images={["blog-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Seamless design-to-code translation with interactive animations"
        paragraphs={[
          `As we transition to development, our front-end developers work to translate the visual design into a seamless, responsive website. Our focus is on preserving every detail, from the color choices to the smallest animations, to maintain the integrity of the design.`,
          `Key Development Elements:`,
          `1. Responsive Design: Coding layouts that adapt flawlessly across devices and screen sizes.`,
          `2. Interactive Animations: Integrating CSS animations and JavaScript to create fluid transitions and hover effects, making the site feel dynamic and engaging.`,
          `3. Efficient, Clean Code: Writing clean, optimized code to ensure fast loading speeds and easy maintenance.`,
        ]}
        blog
      />

    </div>
  );
};

export default Blog2;
