import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Ecc = () => {
  const imageFolder = "ecc";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Construction materials marketplace"
        imageName="ecc"
        subtitle="ECC"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `ECC, a burgeoning online marketplace for construction materials, sought a UI/UX overhaul to enhance user engagement, streamline the browsing experience, and instill trust in the platform. ECC's revamped UI/UX design resulted in a visually appealing, user-friendly platform that resonated with both contractors and suppliers. The simplified navigation, personalized dashboards, and enhanced features increased user engagement, fostering a sense of trust and reliability within the construction materials marketplace.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["ecc-1"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Design research"
        paragraphs={[
          `In-depth user research was conducted to understand the distinct needs of contractors, suppliers, and project managers. Pain points were identified, and user personas were crafted to inform the design process. The design strategy aimed at achieving a harmonious blend of functionality and aesthetics. A clean and intuitive interface was prioritized to simplify complex purchasing processes, while a visually engaging design language aimed to create a sense of professionalism and reliability.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["ecc-2", "ecc-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Supplier side"
        paragraphs={[
          `In our pursuit to optimize user experience within a Construction Materials Marketplace, we recognized the crucial role of suppliers. The goal was to empower suppliers by creating an efficient and user-friendly platform where they could seamlessly send proposals, fostering a collaborative environment within the construction ecosystem. Suppliers can effortlessly submit proposals by entering key details and specifying terms, ensuring a smooth submission process.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["ecc-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Contractor side"
        paragraphs={[
          `The redesigned contractor side has streamlined the tender management process, providing an efficient and user-friendly platform for creating, publishing, and evaluating tenders. With automated comparison tools and real-time tracking, contractors experience enhanced transparency and effectiveness in managing supplier proposals, contributing to the success of the marketplace.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["ecc-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Marketplace features"
        paragraphs={[
          `Discover and manage construction materials effortlessly with our product list screen. All products within a specific category are neatly presented, allowing contractors to browse, filter, and explore details. Easily add desired items to the cart and specify quantities, streamlining the procurement process for a seamless and efficient construction materials marketplace experience. Notification screen, ensuring that contractors are promptly informed about tender updates, new supplier proposals, and essential communications. Setting screen, allowing contractors to personalize preferences, manage account, and fine-tune notification preferences`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["ecc-6", "ecc-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Collaborating closely with stakeholders and domain experts, we embarked on a journey of exploration and discovery to define the UI/UX of the platform. Our goal was to create an intuitive and user-friendly interface that would empower users to easily search for, evaluate, and procure construction materials while fostering transparency and trust between suppliers and buyers”`,
        ]}
        name="Vasilije D."
        position="Deigner"
      />
    </div>
  );
};

export default Ecc;
