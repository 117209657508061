import React, { useRef } from "react";
import About from "./About";
import Award from "./Awards";
import HeroHome from "./HeroHome";

import "./home.css";
import Projects from "./Projects";
import Services from "./Services";
import ShowReel from "./ShowReel";
import WhiteSection from "./WhiteSection";

const Home = ({ projects }) => {
  const awardRef = useRef(null);
  const serviceRef = useRef(null);

  return (
    <div className="home-page">
      <HeroHome />
      <ShowReel />
      <About />
      <WhiteSection />
      <Award sectionRef={awardRef} />
      <Projects
        awardRef={awardRef}
        serviceRef={serviceRef}
        projects={projects}
      />
      <Services sectionRef={serviceRef} />
    </div>
  );
};

export default Home;
