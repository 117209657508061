import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Voltoge = () => {
  const imageFolder = "voltoge";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Development", "Branding", "Logo"]}
        imageFolder={imageFolder}
        title="Accessible clean energy startup website and branding"
        imageName="voltoge"
        subtitle="Voltoge"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Born in 2016, Voltoge ignited a clean energy revolution. Munich-based startup empowers individuals to control their energy destiny with affordable, 24/7 access. Leading the charge in stationary flow battery technology, they are innovative storage systems empower to build a climate-positive future. We did a complete redesign of Voltoge website and logo.`,
        ]}
        buttonText="Live website"
        link="https://voltoge.handmade.company/index.html"
      />

      <ImageGroup
        columns={2}
        images={["voltoge-1", "voltoge-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Design philosophy"
        paragraphs={[
          `We embarked on a transformative journey to redesign their website, focusing on a minimalistic aesthetic while adhering to a disciplined 12-column grid system. The objective was to marry simplicity with functionality, ensuring an elegant and user-friendly platform that aligns seamlessly with Voltoge’s commitment to sustainable energy solutions.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["voltoge-3"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <ImageGroup
        columns={2}
        images={["voltoge-4", "voltoge-5"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <ImageGroup
        columns={1}
        images={["voltoge-6"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <InfoSection
        title="New branding"
        paragraphs={[
          `Curated color palette was chosen to reflect the company's commitment to clean energy, innovation, and sustainability. The minimalistic approach is complemented by a harmonious blend of colors that evoke a sense of reliability, trust, and environmental consciousness.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["voltoge-7", "voltoge-8"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Our goal was to design a website that would not only convey Voltoge's mission and values but also inspire users to join the movement towards cleaner, greener energy solutions. Drawing inspiration from the dynamic forces of nature and the sleek lines of modern technology, we crafted a design that balanced elegance with innovation.”`,
        ]}
        name="Alice K."
        position="Designer"
      />
    </div>
  );
};

export default Voltoge;
