import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Goatagency = () => {
  const imageFolder = "goatagency";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Graphic design", "Branding", "Logo", "Development"]}
        imageFolder={imageFolder}
        title="Marketing agency website and branding"
        imageName="goat"
        subtitle="Goat"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `We started off by creating a website structure that was easy to navigate and understand. We incorporated a navigation menu at the top of the page that allowed visitors to quickly access the various pages on the website. Additionally, we designed a homepage that provided a brief overview of the services that Goat provides`,
        ]}
        buttonText="Live website"
        link="https://goat.handmade.company/index.html"
      />

      <ImageGroup
        columns={1}
        images={["goat-1"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Modern website"
        paragraphs={[
          `The website was designed with the user in mind, making it easy to navigate and find information quickly. We used the latest design trends and incorporated features such as parallax scrolling, animation, and interactive elements to create an engaging and modern experience. We also incorporated an easy-to-use CMS`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["goat-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Process"
        paragraphs={[
          `We started off by creating a website structure that was easy to navigate and understand. We incorporated a navigation menu at the top of the page that allowed visitors to quickly access the various pages on the website. Additionally, we designed a homepage that provided a brief overview of the services that Goat provides`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["goat-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Branding logo"
        paragraphs={[
          `We created a strong, memorable logo. We selected a bold, vibrant font to represent Goat’s dynamic approach to marketing. We also chose a bright and colorful palette to emphasize the creative aspect of the company. Overall, our work allowed the company to stand out in the marketing industry.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["goat-5", "goat-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Visually appealing"
        paragraphs={[
          `To ensure visual appeal, we implemented various design elements. We used bright colors and imagery to grab the attention of visitors and keep them engaged. We also included multiple call-to-action buttons throughout the website to encourage visitors to contact Goat or learn more about their services.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["goat-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Some awards"
        paragraphs={[
          `The awards, received by Goat, are for the overall design, usability and user experience of the website. The website was judged on a variety of factors, including visual aesthetics, content, user friendliness, creativity, and overall effectiveness of the design.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["goat-7"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Throughout the design process, collaboration and communication were essential. We regularly solicited feedback from the agency's team and stakeholders, iterated on the design based on their input, and ensured that everyone was aligned on the website's goals and objectives.”`,
        ]}
        name="Andrej M."
        position="Designer"
      />
    </div>
  );
};

export default Goatagency;
