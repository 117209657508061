import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Crypterium = () => {
  const imageFolder = "crypterium";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Crypto trading webapp"
        imageName="crypterium"
        subtitle="Crypterium"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Crypterium, a digital payments and cryptocurrency company, came to us with a request to redesign their web application's UI and UX. They needed to revamp their product to enhance the user experience and make it easier for customers to get started with their services. Our team set out to create a modern and intuitive design that would make it easier for customers to navigate the web application. We started with a comprehensive research, analyzing their existing platform and understanding the needs of their users.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["crypterium-1", "crypterium-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="General details"
        paragraphs={[
          `Our company designed the UI/UX of a crypto web application to provide the best user experience for users. We used a modern and sleek design to make sure the application was aesthetically pleasing. We incorporated intuitive navigation and user-friendly features to make it easy for users to find what they need with minimal effort. We placed key features at clear points on the page, allowing users to quickly access the information they need.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["crypterium-3", "crypterium-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Data-driven approach"
        paragraphs={[
          `We also used data-driven insights to inform our design decisions. We conducted research on user behavior and preferences to identify key areas of improvement and design accordingly. We utilized visuals to help users understand complex concepts more clearly. We incorporated animations to create an interactive and engaging experience.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["crypterium-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Designing the user interface for a crypto web app was an exhilarating and dynamic experience that immersed me in the rapidly evolving world of cryptocurrency and blockchain technology. From the outset, I was captivated by the opportunity to create a user-centric platform that would empower users to navigate the complexities of the crypto market with confidence and ease.”`,
        ]}
        name="Anthony J."
        position="Designer"
      />
    </div>
  );
};

export default Crypterium;
