import React, { useEffect } from "react";

const ReelModal = ({ close, show }) => {
  useEffect(() => {
    const handlePress = (pressEvent) => {
      if (pressEvent.key === "Escape") {
        close();
      }
    };

    document.addEventListener("keydown", handlePress);

    return () => document.removeEventListener("keydown", handlePress);
  }, []);

  return (
    <div
      style={{
        // opacity: show ? 1 : 0,
        // transition: "500ms",
        position: "relative",
        zIndex: 10000000000000000000,
        // pointerEvents: show ? "initial" : "none",
      }}
    >
      <div className="modal" onClick={close}>
        <iframe
          src="https://player.vimeo.com/video/730048765?h=956c32311e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Showreel"
        ></iframe>
        <div className={`button active-button`} onClick={close}>
          <div className="hamburger-bar">
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </div>
  );
};

export default ReelModal;
