import React from "react";
import Svg from "../../components/shareable/Svg";

const logos = [
  "betero",
  "thread",
  "bift",
  "audio",
  "wipeboard",
  "credd",
  "boost",
  "brivian",
  "imbue",
  "kylin",
  "bgfinest",
  "marmelo",
];

const Logos = () => {
  return (
    <section className="logos white-section section-space">
      <div className="container-narrow animate-element fade-up">
        <h2>Companies that trusted us</h2>

        <div className="view">
          {logos.map((logo, index) => {
            const item = (
              <div className={`item item${index + 1} animate-element fade-up`}>
                <Svg name={logo} />
              </div>
            );

            if (index % 4 === 1) {
              return (
                <React.Fragment key={index}>
                  {item} <div className="blank"></div>
                </React.Fragment>
              );
            } else {
              return <React.Fragment key={index}>{item}</React.Fragment>;
            }
          })}
        </div>
      </div>
    </section>
  );
};

export default Logos;
