import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Alecia = () => {
  const imageFolder = "alecia";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Branding", "Logo", "Graphic design"]}
        imageFolder={imageFolder}
        title="Digital bank branding and website"
        imageName="alecia"
        subtitle="Alecia"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Alecia embarked on a groundbreaking journey to establish a digital bank that resonates with startup clients, fostering a sense of proximity, while maintaining a professional and trustworthy appearance. Alecia not only provides financial services but establishes a meaningful connection with its startup clientele, positioning itself as a catalyst for their financial success.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["alecia-1"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <ImageGroup
        columns={2}
        images={["alecia-2", "alecia-3"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <ImageGroup
        columns={1}
        images={["alecia-4"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <ImageGroup
        columns={2}
        images={["alecia-5", "alecia-6"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <InfoSection
        title="Innovative identity"
        paragraphs={[
          `The design team crafted an innovative visual identity, combining modern elements with a vibrant color palette. The logo and branding materials exude energy and freshness, reflecting the dynamism inherent in the startup ecosystem. While exuding a startup-friendly vibe, Alecia's design strikes a balance by incorporating professional aesthetics. Sophisticated typography and a well-structured layout contribute to a polished appearance, instilling confidence and trust among users.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["alecia-7", "alecia-8"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <ImageGroup
        columns={1}
        images={["alecia-9"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <ImageGroup
        columns={2}
        images={["alecia-10", "alecia-11"]}
        imageFolder={imageFolder}
        onlyDesktop
        noSpace
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Designing the branding for Alecia, the digital bank, was an exhilarating journey that epitomized the fusion of creativity and functionality. From the outset, our team was driven by the vision of crafting a brand identity that not only exuded trust and reliability but also resonated with the modern, tech-savvy consumer.”`,
        ]}
        name="Anthony J."
        position="Designer"
      />
    </div>
  );
};

export default Alecia;
