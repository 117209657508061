import React, { useState, useEffect } from "react";
import ProjectItem from "./ProjectItem";

const RenderBlogs = ({ projects }) => {
  const [printProjects, setPrintProjects] = useState([]);

  useEffect(() => {
    if (projects.length === 0) return;

    let projectsCopy = [...projects];

    let index = 0;

    setPrintProjects(projectsCopy.splice(0, 2));

    if (projectsCopy.length < 2) return;

    const addToPrint = () => {
      const item = projectsCopy[index];
      const img = document.createElement("img");
      img.src = process.env.PUBLIC_URL + `/blogImage/${item.image}.jpg`;

      img.addEventListener("load", () => {
        setPrintProjects((prev) => {
          return [...prev, item];
        });

        index++;
        if (index < projectsCopy.length) {
          addToPrint();
        }
      });
    };
    addToPrint();
  }, [projects]);

  return (
    <section className="render-blogs container-narrow section-space">
      {printProjects.map((project, index) => (
        <ProjectItem key={index} project={project} />
      ))}
    </section>
  );
};

export default RenderBlogs;
