import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Loanscape = () => {
  const imageFolder = "loanscape";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Logo"]}
        imageFolder={imageFolder}
        title="All-in-one platform for brokers and landers"
        imageName="loanscape"
        subtitle="Loanscape"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Revolutionary ecosystem that seamlessly unites lenders and brokers, creating a collaborative platform designed to elevate efficiency, transparency, and trust in the lending process. This innovative project aims to redefine financial interactions, fostering a symbiotic relationship between key players in the lending landscape.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["loanscape-1", "loanscape-2", "loanscape-3", "loanscape-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Brokers side"
        paragraphs={[
          `Centralized dashboard acts as a dynamic compass, providing brokers with a holistic view of lenders' needs. This streamlined approach enhances communication and accelerates the loan origination process. Within the platform, brokers gain access to a carefully curated portfolio of approved lenders, fostering strategic partnerships that align with clients' financial objectives. Collaborative team management features facilitate seamless teamwork, enabling brokers and their teams to collectively contribute to financial success.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["loanscape-5", "loanscape-6", "loanscape-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Onboarding process"
        paragraphs={[
          `Begin by entering your email and password, ensuring a secure start of financial collaboration. After the initial steps, lenders and brokers are prompted to provide essential details, creating a robust profile that enhances trust and transparency. This includes comprehensive information about their professional background, expertise, and specific financial offerings or needs. User-friendly registration flow prioritizes efficiency without compromising on the depth of information. This thorough approach not only ensures a secure onboarding process but also lays the foundation for meaningful connections within the financial ecosystem.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["loanscape-8"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Lenders side"
        paragraphs={[
          `Lenders initiate the process by effortlessly submitting loan applications, ensuring a seamless experience. As applications progress, real-time updates provide insights into their status, keeping lenders informed throughout the collaboration. Platform prioritizes transparency in decision-making. Lenders receive comprehensive feedback on application outcomes, whether approved or denied, fostering clear understanding and constructive engagement.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["loanscape-9", "loanscape-10"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“As the designer behind this innovative platform, I embarked on a journey of discovery and collaboration, guided by the vision of creating a digital space that would revolutionize the lending industry. From the intuitive user interface to the robust backend infrastructure, every element of the web app was meticulously crafted to deliver a seamless and intuitive user experience.”`,
        ]}
        name="Vasilije D."
        position="Designer"
      />
    </div>
  );
};

export default Loanscape;
