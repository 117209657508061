import React from "react";

const AwardItem = ({ item }) => {
  return (
    <li className="animate-element fade-up" data-parent="true">
      <p className="name">{item.name}</p>
      <div className="category">
        <p>{item.category}</p>
        <div className="count">x{item.count}</div>
      </div>
    </li>
  );
};

export default AwardItem;
