import React from "react";

import "./hero.css";

const Hero = ({ children, title, blog, minHeight = false }) => {
  return (
    <section
      className={`hero container-narrow ${minHeight ? "minHeight" : ""}`}
    >
      {blog && (
        <div className="animate-element fade-up-line onload blog-date">
          <p>Blog</p> <p className="blog-dot"></p>{" "}
          <p className="blog-opacity">{blog}</p>
        </div>
      )}
      <h1
        className="separate-text animate-element fade-up-line onload"
        data-text={title}
      >
        {title}
      </h1>
      {children}
    </section>
  );
};

export default Hero;
