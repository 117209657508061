import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Gotoafrica = () => {
  const imageFolder = "gotoafrica";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Travel agency website design"
        imageName="gotoafrica"
        subtitle="Go to Africa"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Go2Africa is a travel agency based in the UK that specializes in African safaris and luxury vacations. After years of success in the travel industry, the agency's leadership decided to expand their business by upgrading an online presence. To accomplish this, they contracted our web design and development team to create a website that accurately reflected the company's brand and showcased the unique and diverse offerings of their African safari and luxury travel packages.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["gotoafrica-1"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Working on the Go2Africey website was a transformative and unforgettable experience as a designer. It was a journey of discovery, creativity, and collaboration that allowed me to showcase Africa's boundless allure and share its timeless wonders with the world.”`,
        ]}
        name="Damir M."
        position="Designer"
      />
    </div>
  );
};

export default Gotoafrica;
