import React from "react";

import Award1 from "../../assets/images/awards/kudos1.png";
import Award2 from "../../assets/images/awards/kudos2.png";
import Award3 from "../../assets/images/awards/inovation2.png";
import Award4 from "../../assets/images/awards/ui1.png";
import Award5 from "../../assets/images/awards/ui2.png";
import Award6 from "../../assets/images/awards/ux1.png";
import Award7 from "../../assets/images/awards/ux2.png";
import Award8 from "../../assets/images/awards/inovation1.png";
import Award9 from "../../assets/images/awards/kudos3.png";

const awards = [
  Award2,
  Award1,
  Award3,
  Award4,
  Award5,
  Award6,
  Award7,
  Award9,
  Award8,
];

const RenderAward = () => {
  return (
    <section className="render-award section-space container-narrow">
      {awards.map((award, index) => (
        <img
          src={award}
          key={index}
          className={`award award${index} animate-element fade-up`}
          alt=""
        />
      ))}
    </section>
  );
};

export default RenderAward;
