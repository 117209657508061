import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Happn = () => {
  const imageFolder = "happn";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Dating app that matches you with people nearby"
        imageName="happn"
        subtitle="Happn"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `In redefining the user experience for Happn, our focus was to create an interface that not only facilitates meaningful connections but also enhances the overall journey of discovering and meeting new people. Leveraging the app's unique real-time location features to foster more organic connections based on proximity.
          `,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["happn-1", "happn-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Effortless journey"
        paragraphs={[
          `Embark on your Happn adventure swiftly by entering your phone number. A one-time SMS code ensures a secure and efficient confirmation, allowing you to dive into the app seamlessly. Next, app invite you to bring your profile to life. Add essential details about yourself, from your interests and favorite music to your passions and hobbies. This step transforms your profile into a vibrant tapestry, providing potential matches with a more comprehensive understanding of who you are beyond the basics.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["happn-3", "happn-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Location integration"
        paragraphs={[
          `We implemented a dynamic map interface that allows users to visualize potential matches in real-time. The location-centric design encourages spontaneous connections, promoting authenticity in interactions. Overall, dynamic location Integration not only enhances the Happn user experience but also aligns the app with the natural rhythm of users' lives, creating a dynamic and engaging platform that reflects the true essence of serendipitous connections.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["happn-5", "happn-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Modernized dating"
        paragraphs={[
          `The redesigned UI/UX of Happn has not only modernized the dating app but has also significantly improved user engagement and satisfaction. The dynamic location features will lead to increased spontaneous connections, and the interactive profiles offer a more comprehensive understanding of potential matches. Happn users will experience a more immersive, authentic, and enriching journey in their quest for meaningful connections.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["happn-7", "happn-8"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“One of the most exhilarating challenges was creating a user experience that balanced privacy and proximity, allowing users to connect with confidence while preserving the thrill of the unknown. We implemented innovative features like real-time location tracking and discreet notifications to ensure that every interaction felt natural and organic, like a chance encounter in the wild.”`,
        ]}
        name="Milosh L."
        position="Designer"
      />
    </div>
  );
};

export default Happn;
