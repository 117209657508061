import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Quran = () => {
  const imageFolder = "quran";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Mobile app design"
        imageName="quran"
        subtitle="Quran"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Project is to create a UI/UX design for a mobile app that will enable users to read, study and learn the Quran. The app will provide users with a modern and engaging experience to help them explore and understand the teachings of the Quran. The app will feature a library of translations and commentaries, audio recordings, interactive study tools and a user-friendly interface The goal is to create an intuitive and engaging app that will help users deepen their knowledge and appreciation of the Quran.`,
        ]}
      />

      <ImageGroup columns={2} 
        images={["quran-1", "quran-2"]} 
        imageFolder={imageFolder} 
        onlyDesktop
      />

      <InfoSection
        title="Easy navigation"
        paragraphs={[
          `The home page should display the full text of the Quran, with an option to quickly search for specific verses. Users should be able to customize their text size, font, and background color to suit their preferences. The app should also include audio recitations of the Quran by renowned reciters, with a simple interface to easily pause, skip, and replay audio. Additionally, users should be able to quickly access translations of the Quran in multiple languages. `,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["quran-3", "quran-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Style"
        paragraphs={[
          `Dark mode for the Quran mobile app is designed to provide users with a comfortable and aesthetically pleasing experience when reading the Quran on their mobile device. The app offers an easy to navigate interface that allows users to switch between light and dark mode, depending on their preference. The app also provides users with the ability to customize the font size, color, and background of the Quranic text to suit their needs.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["quran-5"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Sharing"
        paragraphs={[
          `Users will also be able to access a variety of other features. These include searching for verses and topics, accessing commentaries and translations, and connecting with other users to discuss their experiences.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["quran-6"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Crafting the mobile app for reading the Quaran was like sculpting a digital sanctuary for spiritual nourishment and enlightenment. As the designer behind this sacred project, I embarked on a journey of reverence and creativity, guided by the profound significance of the Quaran in the lives of millions around the world.”`,
        ]}
        name="Vasilije D."
        position="Designer"
      />
    </div>
  );
};

export default Quran;
