import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Demoservices = () => {
  const imageFolder = "demoservices";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Software to control your mails, offices and parcels"
        imageName="demoservices"
        subtitle="Demoservices"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Demoservices embarked on a mission to revolutionize mail management, envisioning a platform where users could effortlessly oversee postboxes, interact with post offices, and manage telecom services all within one cohesive interface. Our UI/UX journey commenced with a focus on user needs. By understanding the varied roles of users - from individuals managing personal mail to businesses coordinating telecom services - we ensured a design that caters to diverse requirements.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["demoservices-1", "demoservices-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Account"
        paragraphs={[
          `Account Management offering users a comprehensive and intuitive platform to oversee their details, finances, and settings. Users can effortlessly update personal information, manage multiple addresses in a centralized address page, and gain financial insights through a dynamic overview of balances and spending across various services. The Settings section allows for personalized customization, tailoring the software experience to individual preferences.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["demoservices-3", "demoservices-4", "demoservices-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Post"
        paragraphs={[
          `Post Management encounter a user-friendly interface that elevates mail management to new heights. The Dashboard serves as a centralized hub, offering a quick overview of incoming and outgoing correspondence. The Inbox feature enables users to efficiently organize and respond to received mail, enhancing overall organization. Simultaneously, the Outbox feature provides transparency into the status of sent items, ensuring users are well-informed about the progress of their correspondence.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["demoservices-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Parcel"
        paragraphs={[
          `In the realm of Parcel Management section, users encounter a streamlined platform designed for effortless oversight of their shipments. The Dashboard acts as a central hub, offering a quick snapshot of all parcel activities, from processing stages to recipient details. My Packages provides real-time updates, ensuring users stay well-informed about their parcels' status and estimated delivery times throughout the shipping process.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["demoservices-7", "demoservices-8"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Results"
        paragraphs={[
          `Demoservices now stands as a trailblazer, offering a unified platform for managing postboxes, post offices, and telecom services. The user-centric design ensures that individuals and businesses alike can navigate this multifaceted landscape with ease, marking a significant leap towards a seamless and integrated best management experience.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["demoservices-9", "demoservices-10"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“From the outset, I was captivated by the opportunity to streamline complex administrative tasks and deliver a unique user experience that would empower users to efficiently manage their mailboxes, offices...”`,
        ]}
        name="Andrej P."
        position="Designer"
      />
    </div>
  );
};

export default Demoservices;
