import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Measured = () => {
  const imageFolder = "measured";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Hair care supplement e-commerce webiste"
        imageName="measured"
        subtitle="Measured"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Our mission with Measured was to create a user-centric e-commerce platform that not only sells hair care supplements but also educates and engages users in their hair health journey. Understanding the sensitivity around hair care, our design revolves around empathy and user education. Clear navigation and engaging visuals were integrated to guide users seamlessly through their exploration and purchase journey.
          `,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["measured-1", "measured-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Optimizing conversions"
        paragraphs={[
          `In our e-commerce design journey, we prioritized the seamless transition of visitors into customers by focusing on clear Call-to-Actions and conducting thorough conversion analysis. By dissecting the conversion funnel, we gained invaluable insights into user behavior, identifying pain points and optimizing the flow for maximum conversion rates.`,
        ]}
      />

      <ImageGroup
        columns={3}
        images={["measured-3", "measured-4", "measured-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Results"
        paragraphs={[
          `Our meticulous approach to clear CTAs and conversion analysis has yielded tangible results. The e-commerce landing page not only boasts a visually appealing and user-friendly design but also demonstrates a significant increase in conversion rates. By understanding user behavior and continuously refining the user experience, we've crafted a landing page that not only attracts but converts, making it an integral part of a successful e-commerce strategy.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["measured-6", "measured-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Orchestrating this transformative project, I embarked on a journey of discovery and creativity, guided by the vision of creating a virtual storefront that would not only showcase Measured's unique offerings but also elevate the online shopping experience to new heights.”`,
        ]}
        name="Robert G."
        position="Designer"
      />
    </div>
  );
};

export default Measured;
