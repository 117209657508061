import React, { useRef } from "react";
import { Link } from "react-router-dom";

const ProjectItem = ({ project }) => {
  const containerRef = useRef(null);

  return (
    <div ref={containerRef} className="item">
      <Link to={`/${project.link}`}>
        <div className="image-animate custom-show">
          <div className="wrapper">
            <img
              src={process.env.PUBLIC_URL + `/blogImage/${project.image}.jpg`}
              alt=""
            />
          </div>
        </div>
        <div className="info">
          <div className="title">{project.title}</div>
          <div className="category">{project.date}</div>
        </div>
      </Link>
    </div>
  );
};

export default ProjectItem;
