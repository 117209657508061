import React from "react";
import Button from "../../components/shareable/Button";

import Clutch from "../../assets/icons/clutch.svg";
import Stars from "../../assets/icons/stars.svg";

const About = () => {
  return (
    <section className="about-us container-narrow section-space animate-element fade-up">
      <h2>A small team producing big work</h2>
      <div className="description">
        <p>
        Handmade Company® is an award-winning design and development agency. Since our founding, we have joined forces with many SMBs and startups, leveraging our digital expertise to create brands, experiences, and products that drive growth and transformation.</p>
        <p>
        What sets us apart is our collaborative approach, driven by continuous improvement and a commitment to excellence. We dive deep into your unique challenges, refining every detail to align with your vision and create solutions that thrive in the market.
        </p>
      </div>
      <div className="clutch-box">
        <a
          href="https://clutch.co/profile/handmade#summary"
          target="_blank"
          rel="noreferrer"
          className="clutch-wrapper"
        >
          <img src={Clutch} className="clutch" alt="" />
        </a>
        <div>
          <img src={Stars} className="stars" alt="" />
          <p className="small-text">
            Rated 5.0/5.0 for design and <br />
            development services
          </p>
        </div>
      </div>
      <Button type="dark" text="About us" href="about" />
    </section>
  );
};

export default About;
