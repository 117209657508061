import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Custathread = () => {
  const imageFolder = "cust";
  return (
    <div className="project-template">
      <TemplateHero
        category={["Branding", "Logo"]}
        imageFolder={imageFolder}
        title="Mens wear branding and logo"
        imageName="custathread"
        subtitle="Cust'a thread"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Our team had the pleasure of creating a logo and branding for a clothing brand. We started the process by understanding the company's vision and mission. We then conducted market research to understand the target audience and the competition. 
          `,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["cust-2", "cust-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Our results"
        paragraphs={[
          `Based on our findings, we created a unique logo design that was eye-catching and memorable. We also developed a color scheme that would reflect the company's mission, as well as an overall look and feel for the brand. Finally, we developed a comprehensive branding guide that outlined the company's core values and identity. This guide was used to ensure consistency across all marketing efforts and to ensure that the brand's message was communicated clearly. `,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["cust-4", "cust-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Package design"
        paragraphs={[
          `We worked together with Cust'a Thread to develop a unique and memorable package design for their clothing. We started by understanding their values and the type of customers they were targeting. Once we had a good sense of it and their target audience, we created mood boards to brainstorm design concepts. 
          `,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["cust-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Designing the branding for a fashion brand was a dream project that allowed me to immerse myself in the world of style, creativity, and self-expression. From the outset, I was energized by the opportunity to shape a brand identity that would resonate with fashion-forward consumers and make a lasting impression in the industry.”`,
        ]}
        name="Andrej P."
        position="Designer"
      />
    </div>
  );
};

export default Custathread;
