import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Sliperymoneys = () => {
  const imageFolder = "sliperymoneys";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Development", "Branding", "Logo"]}
        imageFolder={imageFolder}
        title="E-commerce platform for high risk industries"
        imageName="sliperymoneys"
        subtitle="Slipery"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `The Slipery Moneys provides a secure and legal way for cannabis and other high risk businesses to process traditional payments. It allows businesses to accept payments from customers in a secure and compliant manner and enables businesses to securely transact with customers without having to worry about the risks associated with accepting all traditional payment methods and crypto. Our collaboration with The Slipery Moneys involved the conceptualization and design of a web application. The objective was to create a secure, user-friendly platform that enables businesses to transact seamlessly, adhering to legal and compliance standards specific to the high-risk industry.`,
        ]}
        buttonText="Live website"
        link="https://www.sliperymoneys.handmade.company/index.html"
      />

      <ImageGroup
        columns={2}
        images={["sliperymoneys-1", "sliperymoneys-2", "sliperymoneys-3", "sliperymoneys-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Brand evolution"
        paragraphs={[
          `The brand now focuses on representing diversity in high-risk industries, showcasing businesses from various sectors while maintaining an inclusive and forward-looking perspective. Authentic and relatable visuals are used to resonate with the target audience. New website is a testament to their commitment to providing secure and legal payment solutions. The design and functionality were carefully crafted to reflect the brand's values and offer an intuitive experience for businesses navigating the platform.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["sliperymoneys-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Intuitive dashboard"
        paragraphs={[
          `Dashboard provides users with a powerful tool to manage their financial activities effortlessly. The seamless integration of balance history, order management, and interactive visualizations empowers users to make informed decisions regarding their transactions.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["sliperymoneys-6", "sliperymoneys-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Project outcome"
        paragraphs={[
          `The Slipery Moneys WebApp has successfully transformed the payment landscape for high-risk businesses, providing them with a secure, legal, and efficient means to process traditional payments. The user-friendly interface, coupled with robust security measures and compliance management, positions The Slipery Moneys as a pioneering solution in the financial technology sector, specifically tailored to the needs of high-risk industries.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["sliperymoneys-8"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Crafting both the web app and website for Slippery Moneys was akin to navigating a labyrinth of financial intricacies while painting a canvas of digital innovation. As the designer and developer spearheading this ambitious project, I embarked on a journey of creativity and problem-solving, driven by the vision of creating a seamless and engaging platform.”`,
        ]}
        name="Vasilije D."
        position="Designer"
      />
    </div>
  );
};

export default Sliperymoneys;
