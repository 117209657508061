import React from "react";
import Hover from "./Hover";
import Svg from "./Svg";

const icons = [
  {
    name: "clutch",
    link: "https://clutch.co/profile/handmade",
  },
  {
    name: "instagram",
    link: "https://www.instagram.com/handmade.companyy/",
  },
  {
    name: "behance",
    link: "https://www.behance.net/handmadecompany",
  },
  {
    name: "dribble",
    link: "https://dribbble.com/handmadecompany",
  },
  {
    name: "awwwards",
    link: "https://www.awwwards.com/handmadecompany/",
  },
];

const SocialIcons = () => {
  return (
    <div className="social-icons">
      {icons.map((icon, index) => (
        <Hover key={index}>
          <a href={icon.link}>
            <Svg name={icon.name} />
          </a>
        </Hover>
      ))}
    </div>
  );
};

export default SocialIcons;
