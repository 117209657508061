import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";
import Hero from "../../components/hero/Hero";

const Blog3 = () => {
  const imageFolder = "blog3";
  return (
    <div className="project-template">
      <Hero
        title="Design systems for your start-up"
        blog={"14 SEP 2024"}
        minHeight
      />
      <ImageGroup
        columns={1}
        images={["blog-1"]}
        imageFolder={imageFolder}
        onlyDesktop
        firstImage
      />

      <InfoSection
        title="What is design system"
        paragraphs={[
          `A design system is a set of standards for managing product design through guidelines for using a library of reusable components, text, visuals, etc. Such design systems play an essential role in creating higher-quality, faster, and more cohesive products.`,
          `So, what sets a good design system apart from a less effective one? Here are a few key principles:`,

`- The system provides a clear understanding of how elements should look and behave;`,
`- There’s a unified visual language accepted by marketers, designers, product owners, etc.;`,
`- Components and templates are easy to use and integrate into new product directions;`,
`- The system adheres to accessibility standards, catering to users with various needs.`
        ]}
        blog
      />

      <ImageGroup columns={1} images={["blog-2"]} imageFolder={imageFolder} onlyDesktop />

      <InfoSection
        title="Google Material Design 3"
        paragraphs={[
          `Known for its unique "material metaphor" that mimics physical surfaces, Google’s Material Design creates a natural interaction feel. With its open-source and adaptive design principles like movement and depth, it’s one of the best for intuitive user experience creation.`
        ]}
        blog
      />

      <ImageGroup
        columns={1}
        images={["blog-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Pinterest Gestalt"
        paragraphs={[
          `Pinterest created the Gestalt design system to provide a visually rich, personalized user experience. This system enhances all aspects of the user experience, ensuring consistency, scalability, and accessibility across the platform. With Gestalt, both internal teams and external partners can create cohesive, user-friendly interfaces that align with Pinterest's mission of visual discovery.`
        ]}
        blog
      />

<ImageGroup
        columns={1}
        images={["blog-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Carbon Design System"
        paragraphs={[
          `IBM’s Carbon Design System is a highly thoughtful, practical design system with detailed, well-thought-out guidelines. Its open-source, modular structure supports the creation of a consistent, scalable, and accessible digital experience across IBM’s diverse product range.`
        ]}
        blog
      />

<ImageGroup
        columns={1}
        images={["blog-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Atlassian Design System"
        paragraphs={[
          `A quick look at Atlassian’s design system reveals the depth of attention to detail. Atlassian’s design philosophy centers on enhancing team productivity and potential, which is reflected in its globally used collaboration tools like Trello and Jira.`
        ]}
        blog
      />

<ImageGroup
        columns={1}
        images={["blog-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Wise Design System"
        paragraphs={[
          `Developed for the Wise fintech app, this design system covers branding, tone of voice, lexicon, and interface design guidelines. It provides best practices for handling information about money, different currencies, and transfers, showcasing strong product solutions with a distinctive, memorable brand.`
        ]}
        blog
      />


<ImageGroup
        columns={1}
        images={["blog-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Shopify Polaris"
        paragraphs={[
          `TPolaris is a practical design guide for developing on the Shopify platform. It provides an extensive knowledge base on UI components, visuals, content, and design language to create the best possible user experience and product.`
        ]}
        blog
      />

    </div>
  );
};

export default Blog3;
