import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Hybrid = () => {
  const imageFolder = "hybrid";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Graphic design", "Branding", "Development"]}
        imageFolder={imageFolder}
        title="Website and branding for digital currency"
        imageName="hybrid"
        subtitle="Hybrid"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Hybrid is a secure, private, and anonymous digital currency. It provides users with the ability to send and receive funds without needing to reveal their identity. Our goal was to create a digital space that felt approachable, secure and trustworthy. First, we developed a visual brand identity that reflected the company’s core values. Next, we designed a website that was both user-friendly and secure.`,
        ]}
        buttonText="Live website"
        link="https://hybrid-capital.webflow.io/"
      />

      <ImageGroup
        columns={1}
        images={["hybrid-1"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Website details"
        paragraphs={[
          `We designed a website that uses a dark mode design. Our goal was to create an interface that is intuitive, modern, and easy to use. We used a dark gray and black color palette to create a sleek, minimalistic feel. We incorporated a few bright accents to help draw attention to key features.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["hybrid-2", "hybrid-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Branding"
        paragraphs={[
          `To help give the digital currency a unique identity, we created a brand to go along with it. We used bold, geometric shapes and dark  colors to give the brand a modern look. Using the dark mode was beneficial for the digital currency brand, because it allowed them to create a consistent experience across the website and their branding. `,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["hybrid-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Designing the website for Hybrid, a digital currency poised to redefine the future of finance, was akin to sculpting the landscape of a new digital frontier. From the outset, I was captivated by the opportunity to craft an online presence that would not only showcase the innovative capabilities of Hybrid but also convey a sense of trust, security, and excitement to potential users.”`,
        ]}
        name="Anthony J."
        position="Designer"
      />
    </div>
  );
};

export default Hybrid;
