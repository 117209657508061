import React, { useState, useEffect, useRef } from "react";
import Svg from "./Svg";

import "./movableButton.css";

const MovableButton = ({ type, name, containerRefs }) => {
  const [initLoad, setInitLoad] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [buttonPos, setButtonPos] = useState([0, 0]);
  const [buttonSize, setButtonSize] = useState(8.203);

  const buttonRef = useRef(null);

  useEffect(() => {
    const resizeEvent = () => {
      if (window.innerWidth < 1100) {
        setButtonSize(10.111);
      }
    };

    resizeEvent();

    window.addEventListener("reize", resizeEvent);

    return () => {
      window.removeEventListener("reize", resizeEvent);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth > 900) {
      let mousePosY = 0,
        mousePosX = 0;

      const buttonWidth = buttonRef.current.getBoundingClientRect().width;
      const buttonHeight = buttonRef.current.getBoundingClientRect().height;

      setTimeout(() => {
        setShowButton(false);

        setTimeout(() => {
          setInitLoad(true);
        }, 600);
      }, 0);

      const handleEnter = (e) => {
        setShowButton(true);
      };

      const handleLeave = () => {
        setShowButton(false);
      };

      let disableMove = false;

      const moveElement = (moveEvent) => {
        if (disableMove) return;
        disableMove = true;
        setTimeout(() => {
          disableMove = false;
        }, 50);

        mousePosX = moveEvent.clientX;
        mousePosY = moveEvent.clientY;

        setButtonPos([
          mousePosX - (buttonSize * window.innerWidth) / 100 / 2,
          mousePosY - (buttonSize * window.innerWidth) / 100 / 2,
        ]);
      };

      containerRefs.forEach((container) => {
        container.current.addEventListener("mouseenter", handleEnter);
        container.current.addEventListener("mouseleave", handleLeave);
      });

      const handleScroll = (e) => {
        if (
          mousePosY < containerRefs[0].current.getBoundingClientRect().top ||
          mousePosY >
            containerRefs[
              containerRefs.length - 1
            ].current.getBoundingClientRect().bottom
        ) {
          handleLeave();
        } else {
          handleEnter();
        }
      };

      document.addEventListener("mousemove", moveElement);
      document.addEventListener("scroll", handleScroll);
      return () => {
        document.removeEventListener("scroll", handleScroll);
        document.removeEventListener("mousemove", moveElement);
      };
    }
  }, []);

  return (
    <div
      className={`${name}-button movable-button`}
      style={{
        opacity: initLoad ? 1 : 0,
        transform: showButton ? "scale(1)" : "scale(0)",
        left: buttonPos[0],
        top: buttonPos[1],
        width: `${buttonSize}vw`,
        height: `${buttonSize}vw`,
      }}
      ref={buttonRef}
    >
      {type === "btn" ? <Svg name={`${name}`} /> : <p>{name}</p>}
    </div>
  );
};

export default MovableButton;
