import React from "react";
import Hero from "../../components/hero/Hero";
import RenderProjects from "./RenderProjects";

import "./projects.css";

const Projects = ({ projects }) => {
  return (
    <div className="content-hero">
      <Hero title="We do amazing things with amazing people" />
      <RenderProjects projects={projects} />
    </div>
  );
};

export default Projects;
