import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Bgfinest = () => {
  const imageFolder = "bgfinest";
  return (
    <div className="project-template">
      <TemplateHero
        category={["Branding", "Logo"]}
        imageFolder={imageFolder}
        title="Concierge service branding"
        imageName="belgradesfinest"
        subtitle="Belgrades Finest"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `At Belgrades Finest, we wanted to create a modern, luxurious brand identity that would stand out in the competitive concierge services market. We used a combination of sophisticated design elements, bold typography and vibrant colors to create an identity that captures the spirit of the company.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["bgfinest-1", "bgfinest-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Assignment"
        paragraphs={[
          `The logo features a strong and contemporary typeface that conveys a sense of professionalism. We chose a vibrant blue palette to add energy to the logo and to create a visual contrast with the classic black-and-white color scheme of the branding. The imagery is simple and elegant, featuring images of luxury cars and yachts to emphasize the luxuriousness of the service.
          `,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["bgfinest-3", "bgfinest-4", "bgfinest-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Naming"
        paragraphs={[
          `Having secured a large amount of funding, Belgrades Finest had the means it needed to reposition itself with its target audience. Naming combines the location where the company provides services, the year of foundation and the adjective "Finest", which characterizes the best service`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["bgfinest-6", "bgfinest-7", "bgfinest-8"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Branding"
        paragraphs={[
          `We began by defining the core values of the company: luxury, exclusivity, and convenience. With these values in mind, we created a sophisticated visual identity that was both timeless and luxurious. Our team also designed stylish business cards and brochures to reinforce the brand’s identity. The cards feature a striking blue background with the distinctive Belgrades Finest logo, and the brochures feature elegant photography and a clean layout.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["bgfinest-9", "bgfinest-10"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Designing the branding for "Belgrade's Finest," a premier concierge service, was a truly inspiring and rewarding experience. From the outset, I was captivated by the opportunity to craft a brand identity that would embody the sophistication, luxury, and unparalleled service that "Belgrade's Finest" aspired to deliver.”`,
        ]}
        name="Damir M."
        position="Designer"
      />
    </div>
  );
};

export default Bgfinest;
