import React from "react";
import FaqItem from "./FaqItem";

const lists = [
  {
    question: "What separates Handmade from other digital agencies?",
    answer:
      "What sets Handmade apart from other digital agencies is our commitment to a truly collaborative and personalized approach. We believe that every project is unique, and we dive deep into understanding our clients’ vision, challenges, and goals. Our multidisciplinary team combines expertise in design, development, and strategy, allowing us to deliver comprehensive, end-to-end solutions that are tailored specifically to each client. At Handmade, we don’t just deliver a final product—we build long-term partnerships focused on continuous improvement and excellence. Our process is iterative, with close client involvement at every stage, ensuring that each detail aligns with the brand’s identity and business objectives. We’re driven by a passion for innovation and quality, which translates into digital experiences that stand out in the market, resonate with users, and support sustainable growth for the brands we serve.",
  },
  {
    question: "What is your key expertise?",
    answer:
      "Our key expertise lies in partnering with SMBs and startups to bring their ideas to life through complete product development and design. We guide our clients from the very first stages of ideation through design, prototyping, testing, and finally to development and launch. Our skilled development team delivers high-quality web and mobile products, using popular and reliable technologies tailored to each project’s unique needs. Beyond product development, we offer a range of creative services, including illustration, branding, and animation, to help brands establish a distinct identity and engage their audiences. By combining strategic design with technical excellence, we empower ambitious businesses to stand out, grow, and make a lasting impact in their markets.",
  },
  {
    question: "Do you do branding or should I hire a separate branding agency?",
    answer:
      "Yes, we offer comprehensive branding services as part of our expertise. Our team specializes in creating cohesive brand identities that include logo design, brand guidelines, custom illustrations, and even animations to bring your brand’s personality to life. We can handle everything from the visual identity to the messaging, ensuring that your brand stands out and resonates with your target audience. By working with us, you get a unified approach to both branding and product development, which means all elements of your brand and digital presence will be seamlessly aligned. There’s no need to hire a separate branding agency—we provide end-to-end solutions to help your brand make a lasting impression.",
  },
  {
    question: "How much does it cost to hire you for a project?",
    answer:
      "Our pricing is directly related to the project scope, timeline and deliverables. However, all our engagements are fixed‑price or monthly subscription contracts. In this case, we’ll give you a detailed proposal after learning as much as possible about your project. Please email us to get a quote.",
  },
  {
    question: "How can I get a proposal for my project?",
    answer:
      "If you're looking for a design or development agency, please send us an email first with a quick summary of your project. We usually reply within 24 hours to schedule an introductory call and ask any immediate questions. During our first call, we will ask a lot of questions about the project goals, audience, budget, timeline, and other essential details. We can sign an NDA if necessary, but we always treat all client conversations as confidential. While many agencies only work by the hour, we try to estimate the project cost as precisely as possible before we start. Alternatively, you can take advantage of our flexible subscription model. After the call, we may request various documentation such as a design brief, project requirements, past designs, etc.",
  },
  {
    question: "How am I billed for your design subscription services and is there a contract?",
    answer:
      "Our design subscription service is billed monthly at a flat rate, with no long-term contract required. You can cancel or pause the subscription at any time, providing full flexibility. All billing details and services are managed month-to-month, so you only pay as long as you’re using the service.",
  },
  {
    question: "What tools and technology will you use to build my website and/or app?",
    answer:
      "We use only the tools and technology that will be most effective for your business. We will determine the most suitable platform for your needs and then design and develop your product accordingly. We are proficient in platforms like Shopify for e-commerce, Webflow for websites, and custom HTML/CSS/JS/REACT for websites and apps.",
  },
  {
    question: "Do you provide any website/app warranty or guarantee?",
    answer:
      "Yes. We stand by our products and service 100% of the time. We have a 30-day bug testing period that normally allows us to hash out any bugs or issues that we may be experiencing. However, if something breaks on your website unexpectedly, we will be there to fix it and get you back up and running assuming the break was not caused by some other additional developer that was working on the site. But developing apps is more complex, as they require continuous support from our development team due to regular updates for mobile devices, including iOS and Android. And we have special packages for that.",
  },
];

const Faq = () => {
  return (
    <section className="faq section-space container-narrow">
      <h2 className="animate-element fade-up">Frequently asked questions</h2>

      <div className="faq-wrapper">
        {lists.map((item, index) => (
          <FaqItem key={index} item={item} />
        ))}
      </div>
    </section>
  );
};

export default Faq;
