import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Darkfrontierspage = () => {
  const imageFolder = "frontiers";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Development"]}
        imageFolder={imageFolder}
        title="NFT collection website"
        imageName="darkfrontiers"
        subtitle="Dark Frontiers"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Dark Frontiers NFT Collection, created by renowned artist and entrepreneur J.D. Brown, is an incredible new collection of digital art that is sure to delight any collector. In order to maximize the potential of this collection and make it more accessible to potential buyers, we created a custom landing page.`,
        ]}
        buttonText="Live website"
        link="https://darkfrontiers.handmade.company/index.html"
      />

      <ImageGroup
        columns={1}
        images={["frontiers-1"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="User experience"
        paragraphs={[
          `Our focus was to create a visually engaging and user-friendly page to showcase the collection. We started by designing the page in a modern and clean style with a focus on the art. We used high-quality images of the artwork to draw attention to the collection and make it stand out. We also incorporated interactive elements to allow users to explore the collection in more depth.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["frontiers-2", "frontiers-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Final product"
        paragraphs={[
          `The landing page was designed to be clean and modern, with a focus on highlighting the unique elements of each token. We used a combination of bold colors and striking imagery to create a visually engaging experience. We’re proud of the landing page we created for Dark Frontiers and we believe it is a great way to introduce users to the world of NFTs.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["frontiers-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Multiple awards"
        paragraphs={[
          `We are pleased to announce that our website has recently been awarded multiple accolades from Awwwards and CSS Design Awards. Our website was recognized with an Awwwards Honorable mention and a CSS Design Awards Special Kudos, UI adn UX Awards. We are proud to be recognized by two of the most prestigious design awards in the industry and would like to thank our team for their hard work and dedication in creating this award-winning website.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["frontiers-5"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Working as a developer on the landing page for NFT Dark Frontiers was an exhilarating and challenging experience that pushed the boundaries of creativity and technical expertise. From the outset, I was excited by the opportunity to create a visually stunning and engaging web experience that would captivate visitors and showcase the unique world of NFT Dark Frontiers.”`,
        ]}
        name="Andreas M."
        position="Developer"
      />
    </div>
  );
};

export default Darkfrontierspage;
