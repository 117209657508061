import React from "react";
import Button from "../../components/shareable/Button";

const WhiteSection = () => {
  return (
    <section className="white-section cta-area">
      <div className="animate-element fade-up">
        <p className="subtitle">Scale up your business!</p>
        <h2
          className="separate-text"
          data-text="Let’s make something great together!"
        >
          Let’s make something great together!
        </h2>
      </div>
      <Button type="dark" text="Contact us" href="contact" />
    </section>
  );
};

export default WhiteSection;
