import React from "react";
import Hover from "../shareable/Hover";

import { Link } from "react-router-dom";

const MenuItem = ({ text, link }) => {
  return (
    <Link to={link}>
      <li>
        <Hover>{text}</Hover>
      </li>
    </Link>
  );
};

export default MenuItem;
