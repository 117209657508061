import React from "react";

import Sprite from "../../assets/images/sprite.svg";

const Svg = ({ name }) => {
  return (
    <svg className={`icon ${name}`}>
      <use href={`${Sprite}#${name}`}></use>
    </svg>
  );
};

export default Svg;
