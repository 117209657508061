import React, { useState, useEffect } from "react";
import ServicesItem from "./ServicesItem";

const servicesList = [
  {
    category: "ui/ux",
    title: "UI/UX design",
    desc: "We design simple, beautiful and differentiated digital products. A lot goes into that, but if we boil our approach down to one objective. It would be discovering what users want and designing the simplest way for them to get there. We can run this process from start to finish, or act as an extension of your product team.",
    capabilities:
      "Interface design, Usability, Interactions, User experience, Accessibility and etc.",
  },
  {
    category: "development",
    title: "Development",
    desc: "Work with our experts to implement the newest, cutting-edge technologies into your business or startup and kick-start your growth. We focus on the newest technologies and frameworks, trends, data analysis and research to bring you optimal results.",
    capabilities: "React, React Native, Webflow, Shopify, Node and etc.",
  },
  {
    category: "animation",
    title: "Illustration and animation",
    desc: "Custom graphics present a popular effective way to make a digital product attractive and emotional. Animation is a proven way to breathe life in a digital product or marketing campaign. ",
    capabilities:
      "Promo videos, Interaction design, Motion graphics, Graphic design and etc.",
  },
  {
    category: "branding",
    title: "Branding",
    desc: "A brand today is all about how it makes your customers feel. It's not a logo, visual identity, or digital product design, but rather a cohesive system that spans across all mediums and touchpoints. We are offering a complete solution from naming and logo design to communications and style guides.",
    capabilities:
      "Brand identity, Branding styleguide, Logo design, Imagery, Positioning and etc. ",
  },
  {
    category: "designsubcription",
    title: "Design subscription",
    desc: "A design subscription offers companies ongoing access to professional design services for a flat monthly fee, without the need for long-term contracts. Clients enjoy unlimited design requests, fast turnaround times (often within 48-72 hours), and the flexibility to cancel or pause as needed. ",
    capabilities:
      "All design services and Webflow development",
  },
];

const RenderServices = ({ projects }) => {
  const [filteredProjects, setFilteredProjects] = useState([[], [], [], [], []]);

  useEffect(() => {
    setFilteredProjects((prev) => {
      for (let i = 0; i < servicesList.length; i++) {
        prev[i] = projects.filter(
          (project) => project.service === servicesList[i].category
        );
      }

      return [...prev];
    });
  }, [projects]);

  return (
    <section className="render-services section-space container-narrow">
      {servicesList.map((service, index) => (
        <ServicesItem
          item={service}
          key={index}
          projects={filteredProjects[index]}
        />
      ))}
    </section>
  );
};

export default RenderServices;
