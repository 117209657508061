import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Brivian = () => {
  const imageFolder = "brivian";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Branding", "Logo"]}
        imageFolder={imageFolder}
        title="IT company branding and website design"
        imageName="brivian"
        subtitle="Brivian"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Brivian, an IT company, needed a logo and branding that would reflect its modern, innovative, and high-tech nature. We began the design process by getting to know the company and its goals. After researching the competition and the industry, we created several concept designs that showcased the company’s values.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["brivian-1", "brivian-2", "brivian-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="General style"
        paragraphs={[
          `We then refined the concept designs to create a logo and branding package that embodied the company’s vision. We used bold colors to represent the energy and enthusiasm of the company. We also used clean lines and modern shapes to convey the modern and innovative nature of the business. Finally, to create a cohesive branding package, we developed a corporate color palette, typography, and an overall visual style. This allowed us to create a unified and recognizable look for Brivian that would be instantly recognizable to customers and potential partners.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["brivian-4", "brivian-5", "brivian-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Palette"
        paragraphs={[
          `We used colors wich reflect innovation and reliability. Our team decided to deviate from the typical IT companies branding guides and paletes, and we chose fresher colors: crimson, white, and blue. We successfully implement that colors, improved overall experience and simplified using of them.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["brivian-7", "brivian-8", "brivian-9"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `”Designing the branding for Brivian, an IT company, was an exhilarating and fulfilling endeavor that allowed me to delve into the innovative world of technology and creativity. From the outset, I was inspired by the opportunity to craft a brand identity that would encapsulate Brivian's expertise, forward-thinking approach, and commitment to excellence.”`,
        ]}
        name="Vasilije D."
        position="Designer"
      />
    </div>
  );
};

export default Brivian;
